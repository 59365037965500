.generalContainderComidasAnaquelesResult {
    display: flex;
    align-items: center; 
    justify-content: center; 

    height: 100vh; 
    width: 100vw; 

    padding: 0px;
    margin: 0px;
}

.containerInicioComidasAnaquelesResult {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #ccc; /*Esto se va a comentar*/

    display: flex;
    align-items: center;
    justify-content: center;

    width: 70%;
    height: 90%;
}

.imagecontainerComidasAnaquelesResult {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #a52b2b; /*Esto se va a comentar*/

    background-image: url('../../../../public/assets/img/ImgComidas/Img_Res_Anaquel_Comidas.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    width: 100%;
    max-width: 20.633rem;
    height: auto;
    aspect-ratio: 29.633 / 44.686;
}

.buttonComidasAnaquelesResult {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #887676; /*Esto se va a comentar*/

    background-image: url('../../../../public/assets/img/General/Btn_Siguiente.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    width: 45%;
    max-width: 29.633rem;
    height: 8%;
    aspect-ratio: 29.633 / 44.686;
    
    position: absolute;
    top: 85%;

    
    background-color: transparent;
    border-color: transparent;
    
}

.scoreAnaquelesComidas {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #887676; /*Esto se va a comentar*/

    width: 40%;
    max-width: 29.633rem;
    height: 8%;
    aspect-ratio: 29.633 / 44.686;

    position: absolute;
    top: 18%;
    left: 52%;

    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    color: red;
    font-size: calc(2em + 1vw);
    font-family: monserratBold;
}
