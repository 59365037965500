.generalContainerRegister {
    display: flex;
    align-items: center; 
    justify-content: center; 

    width: 100%; 

    padding: 0px;
    margin: 0px;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #1e6f9e; /*Esto se va a comentar*/
}

.register-subgeneral-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%; 
    width: 100%; 
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #1e9e3e; /*Esto se va a comentar*/
}

.register-btn-return-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: flex-start;

    width: 100%; 

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #071e69; /*Esto se va a comentar*/
}

.start-btn-return{

    background-image:  url('../../../public/assets/img/UI/Img_button_return_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    
    max-width: 4rem;
    width: 100%;
    height: auto;
    aspect-ratio: 10.938 / 10.5;
    z-index: 1;
  }

.register-form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 100%; 
    width: 100%; 
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #dfd367; /*Esto se va a comentar*/
}

.register-form-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #67dfa7; /*Esto se va a comentar*/
}

.register-img-form-container {

    background-image:  url('../../../public/assets/img/ImgLogin/Register_Img.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;

    max-width: 27rem;
    width: 100%;
    height: auto;
    aspect-ratio: 42.813 / 67.438;


    /*border: 1px solid black; /*Esto se va a comentar*/
}

.register-form{
    max-width: 25.3rem;
    width: 100%;
    height: 85%;
    aspect-ratio: 42.813 / 67.438;

    margin-left: 1.3rem;
    margin-top: 4.4rem;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #67dfa7; /*Esto se va a comentar*/
} 


label {
    margin-left: 10%;
    font-size: 0.8rem !important;
    font-weight: bold;
    margin-top: 0rem !important;
}

input, select {
    width: 80% !important;
    height: 2rem;
    margin: 0 auto;
    border-radius: 0% !important;
    background-color: white !important;
    font-size: .8rem !important;
}



.register-btn-registrar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    height: 8%; 
    width: 99%; 

    padding: 0px;
    margin: 0px;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #071e69; /*Esto se va a comentar*/
}

.register-btn-registrar {
    background-image:  url('../../../public/assets/img/ImgLogin/Login_Btn_Ingresar_1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;

    max-width: 9rem;
    width: 100%;
    height: auto;
    aspect-ratio: 67.438 / 30.813;

    margin-top: 1.2rem;
    margin-left: -1rem;
}

.register-form .row .col-md-12 .mb-3 label{
    font-family: monserratBlack;
}

@media only screen and (min-width: 1440px) {

    label {
        margin-left: 10%;
        font-size: 0.75rem !important;
        font-weight: bold;
        margin-top: 0rem !important;
    }

    input, select {
        width: 80% !important;
        height: 1.8rem;
        margin: 0 auto;
        border-radius: 0% !important;
        background-color: white !important;
        font-size: .7rem !important;
    }

    .input-group-text {
        height: 1.8rem;
    }

    .register-form-expand-errors .row .col-md-12 .mb-0{
        height: 4.8rem;
    }

    .register-form .row .col-md-12 .mb-0 .invalid-feedback {
        font-size: 0.6rem !important;
    }
}


@media only screen and (max-width: 1439px) {

    label {
        margin-left: 10%;
        font-size: 0.75rem !important;
        font-weight: bold;
        margin-top: 0rem !important;
    }

    input, select {
        width: 80% !important;
        height: 1.8rem;
        margin: 0 auto;
        border-radius: 0% !important;
        background-color: white !important;
        font-size: .7rem !important;
    }

    .input-group-text {
        height: 1.8rem;
    }

    .register-form-expand-errors .row .col-md-12 .mb-0{
        height: 4.8rem;
    }

    .register-form-expand-errors .row .col-md-12 .mb-0 .invalid-feedback {
        font-size: 0.6rem !important;
    }
}

@media only screen and (max-width: 600px) {

    .register-form .row {
        margin-left: -2rem;
    }

    label {
        margin-left: 10%;
        font-size: 0.65rem !important;
        font-weight: bold;
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
    }

    input, select {
        width: 80% !important;
        height: 1.5rem;
        margin: 0 auto;
        border-radius: 0% !important;
        background-color: white !important;
        font-size: 0.55rem !important;
    }

    .input-group-text {
        height: 1.5rem;
        font-size: 0.55rem;
    }

    .register-form-expand-errors .row .col-md-12 .mb-0{
        height: 3.8rem;
    }

    .register-form-expand-errors .row .col-md-12 .mb-0 .invalid-feedback {
        font-size: 0.55rem !important;
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .start-btn-return{
        
        max-width: 2.5rem;
        width: 100%;
    }

    .register-form-container { 
        width: 20rem; 
        margin-top: -1rem;
    }

    .register-form-expand-errors .row .col-md-12 .mb-0{
        margin-bottom: -.2rem !important;
    }

    .register-form .row .col-md-12 .mb-3 {
        margin-bottom: .5rem !important;
    }

    .register-btn-registrar {
        margin-top: -1rem;
    }
}

