  .glider-prev,
  .glider-next {
    top: .5rem;
  }
  
  .glider-prev {
    left: 18rem;
  }
  
  .glider-next {
    right: 18rem;
  }
  
  .glider {
    height: 200px;
  }
  .glider-contain {
    margin-bottom: 25px;
  }
  .glider-dots {
    margin-top: 15px;
  }
  .glider-track {
    height: 100%;
  }
  .glider-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f5f5f5;
  }
  
  .container {
    max-width: 90%;
    margin: 0 auto;
  }
  
  .slide {
    font-size: 3em;
    font-weight: bold;
  }


  .glider {
    width: 17rem !important;
    height: 3.8rem;
  }

  .glider, .glider-contain {
    margin: inherit !important;
    position: inherit !important;
    margin-left: .34rem !important;
    margin-top: .2rem !important;
  }

  .glider-slide {
    min-width: 0 !important;
    width: 33% !important;
    border: transparent
  }
  
  .btn-sliderlist-img{
    background: transparent;
    border: transparent;
    padding: 0.16rem;
  }


    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {

      .glider {
        width: 14.9rem !important;
        height: 3.8rem;
      }
    }