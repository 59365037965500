.startPage-GeneralContainer {
    display: flex;
    align-items: center; 
    justify-content: center;

    height: 100vh; 
    width: 100%;

    padding: 20px;
}

.startPage-Container {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #505050; /*Esto se va a comentar*/

    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;

    padding: 10px;
}

.startPage-VideoContainer {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #ccc; /*Esto se va a comentar*/

    display: flex;
    align-items: center;
    
    width: 60%;
    height: 100%;

    padding: 10px;
}

.startPage-video {
    max-width: 67.5rem; 
    width: 100%; 
    max-height: 120rem; 
    height: 100%;
}

.startPage-BtnContainer {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #ccc; /*Esto se va a comentar*/

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    width: 40%;
    height: 100%;

    padding: 20px;
}

.start-btn-entrar{
    background-image:  url('../../../public/assets/img/StartPage/StartPage_Btn_Entrar.png');
    background-size: contain;
    background-repeat: no-repeat;
  
    max-width: 14.711rem; 
    width: 100%; 
    max-height: 6.059rem; 
    height: 100%;
    
    border-color: transparent;
    background-color: transparent;
}

/* Media query para dispositivos con pantalla pequeña (por ejemplo, teléfonos móviles) */
@media (max-width: 800px) {

    .startPage-GeneralContainer {
        padding: 10px;
    }
        
    .startPage-Container {
        position: relative;
    }
    
    .startPage-VideoContainer {
        position: absolute;
        width: 100%;
    }
    
    .startPage-BtnContainer {
        position: absolute;
        width: 100%;
        padding: 20px;
        pointer-events: none;
    }
    
    .start-btn-entrar{
        max-width: 11.924rem; 
        max-height: 4.911rem;
        pointer-events: all;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .startPage-GeneralContainer{
        height: 80vh;
    }

    .startPage-VideoContainer{
        position: initial;
    }
}