
.menu-btn{
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #c77373; /*Esto se va a comentar*/

    background-image:  url('../../../public/assets/img/UI/Img_button_exe_01_.png');
    background-size: contain;
    background-repeat: no-repeat;

    display: inline-block;
    transition: all 0.3s ease;
    max-width: 325px; 
    width: 100%; 
    max-height: 93px; 
    height: 100%; 

    padding-top: 1.5%;
    padding-right: 3%;
    padding-left: 4%;
    padding-bottom: 4%;

    border-color: transparent;
    background-color: transparent;
  }
  
  .menu-txt{
    margin: auto;
    color: white;
    font-size: large;
    font-weight: 900;
  }
  
  .icon-check{
    width: auto;
    height: 1.5rem;
    float: right;
    margin-right: .2rem;
    margin-top: -1.8rem;
   }

  @media (max-width: 800px) {
    .menu-btn{
      /* border: 1px solid black; /*Esto se va a comentar*/
      /* background: #c77373; /*Esto se va a comentar*/
  
      background-image:  url('../../../public/assets/img/UI/Img_button_exe_01_.png');
      background-size: contain;
      background-repeat: no-repeat;
  
      display: inline-block;
      transition: all 0.3s ease;
      max-width: 278px; 
      width: 100%; 
      max-height: 79px; 
      height: 100%; 
  
      padding-top: 1.5%;
      padding-right: 3%;
      padding-left: 4%;
      padding-bottom: 4%;
  
      border-color: transparent;
      background-color: transparent;
    }
  }

  /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .icon-check{
    margin-right: -.3rem;
    margin-top: -1rem;
    height: 1rem;
   }
 }

  /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .icon-check{
    margin-right: 0.3rem;
    margin-top: -1.5rem;
    height: 1.3rem;
   }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .icon-check{
    margin-right: -.8rem;
    margin-top: -.5rem;
    height: 1rem;
   }
}

@media only screen and (min-width: 992px) {
  .icon-check{
    width: auto;
    height: 1.5rem;
    float: right;
    margin-right: -.4rem;
    margin-top: -1.8rem;
   }
}
 
