.panel-heading{
    margin-bottom: 2rem;
}

.panel-primary{
    margin-bottom: 3rem;
}

.list-group-item{
    border: solid 4px black;
    border-radius: 0% !important;
    margin-bottom: 1rem;
    background-color: #eb262c;
    box-shadow: -.4rem .4rem .3rem 1px #707070;
    padding: 0;
}

.list-group-item.list-group-item {
    border-top-width: thick ;
}

.center-parent{
    display: flex;
    align-items: center;
}

.center-child{
    margin: 0 auto;
}

.with-container{
    width: 50%;
}

.list-group-item-buttom{
    width: 100%;
    background-color: transparent;
    color: white;
}

.filter-brightness{
    filter: brightness(0.5);
}

.testContainer{
    position: inherit !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
}

.btn-test{
    display: flex;
    background-image:  url('../../../public/assets/img/ImgCuestionario/Cuestionario_Btn_Listo.png');
    background-size: contain;
    background-repeat: no-repeat;
  background-position: center;
    max-width: 14.711rem; 
    width: 100%; 
    max-height: 6.059rem; 
    height: 4rem;
    margin: 0 auto;
    border-color: transparent;
    background-color: transparent;
}

@media only screen and (max-width: 600px) {
    .with-container{
        width: 100%;
    }
}