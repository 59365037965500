.comidas-tortillero-cooler-image{
    height: 38em;
    width: 14rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/ImgComidas/Tortillero/Img_Comidas_Tortillero_01.png');
    background-repeat: no-repeat;
    float: right;
    margin-right: 6rem;
}

.comidas-tortillero-cooler-slider{
    background-color: transparent;
    height: 29rem;
    width: 19.1rem;
    background-size: cover;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.comidas-tortillero-cooler-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/UI/Img_content_01_.png');
    background-repeat: no-repeat;
}

.comidas-tortillero-cooler-button{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbiriParrillas.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.comidas-tortillero-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .comidas-tortillero-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .comidas-tortillero-cooler-slider-container{
    margin-top: 4rem;
  }

  .comidas-tortillero-cooler-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 3rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .comidas-tortillero-cooler-slider-image img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  .comidas-tortillero-cooler-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    top:3.5rem;
    left: .21rem;
  }

  .comidas-tortillero-background-transparent{
    background: transparent;
  }

  .comidas-tortillero-cooler-btn{
    height: 7.2rem;
    width: 12.4rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: .8rem;
    overflow: hidden;
  }

  
  .comidas-tortillero-cooler-btn.oneBtn{
    margin-top: 15.3rem;
  }

  .comidas-tortillero-cooler-btn.twoBtn{
    margin-top: 1.8rem;
  }

  .comidas-tortillero-cooler-button p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .comidas-tortillero-cooler-btn-selected{
    border-color: #40ca6e !important;
  }

  .comidas-tortillero-cooler-btn img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-tortillero-cooler-flex-direction{
    flex-direction: column;
  }

  .comidas-tortillero-margin-top{
    margin-top: -1.5rem !important;
  }


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .comidas-tortillero-cooler-image{
    height: 30em;
    width: 11.3rem;
    float: none !important;
    margin-right: 0 !important;
    margin: 0 auto !important;
    margin-top: 2.5rem !important;
  }

  .comidas-tortillero-cooler-slider{
    display: none ;
    height: 26.5em !important;
    width: 17.4rem !important;
  }

  .comidas-tortillero-cooler-slider-mobile{
    display: block !important;
    margin-top: 3.5rem !important;
    margin-bottom: 2.6rem !important;
    margin: 0 auto ;
  }
  .comidas-tortillero-cooler-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .comidas-tortillero-cooler-image-mobile{
    display: none !important;
  }

  .comidas-tortillero-cooler-slider-list{
    width: 16rem !important;
    height: 6rem !important;
    top:2rem !important;
    left: .34rem !important;
  }

  .comidas-tortillero-cooler-slider-image{
    width: 14rem !important;
    margin-top: 3rem !important;
  }

  .comidas-tortillero-cooler-slider-container{
    margin-top: 1.5rem !important;
  }

  .comidas-tortillero-cooler-btn{
    height: 5.9rem;
    width: 10.1rem;
    margin-left: .6rem;
  }

  
  .comidas-tortillero-cooler-btn.oneBtn{
    margin-top: 12.3rem;
  }

  .comidas-tortillero-cooler-btn.twoBtn{
    margin-top: 1.5rem;
  }

}

@media only screen and (min-width: 600px) {
  .comidas-tortillero-cooler-image, .comidas-tortillero-cooler-slider{
    display: block !important;
    margin-top: 2.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}