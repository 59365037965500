.generalContainerLogin {
    display: flex;
    align-items: center; 
    justify-content: center; 

    height: 100vh; 
    width: 100%; 

    padding: 0px;
    margin: 0px;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #1e6f9e; /*Esto se va a comentar*/
}

.loginDad{
    padding: 5% 0;
}

.loginSon{
    padding: 10% 0;
}

.containerAllLogin {
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 95%;
    height: 95%;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #3a9e1e; /*Esto se va a comentar*/
}

.containerLogin {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #354b2f; /*Esto se va a comentar*/
}

.login-btns-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 20%;

    padding-top: 1rem;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #abe99a; /*Esto se va a comentar*/
}

.login-btns-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    max-width: 32.147rem;
    height: auto;
    aspect-ratio: 32.147 / 24.025;

    margin-left: 1rem;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #d49827; /*Esto se va a comentar*/
}

.login-form-main{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #5a26d4; /*Esto se va a comentar*/
}

.login-form-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    background-image: url('../../../public/assets/img/UI/Img_content_login_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    
    margin: 0 auto;
    padding: 0;
    
    width: 100%;
    max-width: 32.147rem;
    height: auto;
    aspect-ratio: 32.147 / 24.025;
}

.login-start-btn-registrar{
    background-image:  url('../../../public/assets/img/ImgLogin/Login_Btn_Ingresar_1.png');
    background-size: contain;
    background-repeat: no-repeat;
  
    max-width: 14.711rem; 
    width: 100%; 
    max-height: 6.059rem; 
    height: 100%;
    
    border-color: transparent;
    background-color: transparent;
  }

  .start-btn-registrar{
    background-image:  url('../../../public/assets/img/General/Btn_Ejecutar.png');
    background-size: cover;
    background-repeat: no-repeat;
  
    max-width: 14.711rem; 
    width: 7.5rem; 
    max-height: 6.059rem; 
    height: 3.2rem;
    
    border-color: transparent;
    background-color: transparent;
  }

.todoContainer {
    width: 100%;
    max-width: 30.8rem;
    height: auto;
    aspect-ratio: 30.8 / 18.5;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    position: relative;
    left: 3.5%;
    margin-top: 13%;

    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #d89e9e; /*Esto se va a comentar*/
}

input {
    width: 80% !important;
    height: 2rem;
    margin: 0 auto;
    border-radius: 0% !important;
    background-color: white;
    margin-right: 10% !important
}

span {
    margin-left: 10% !important;
    height: 2rem;
    margin: 0 auto;
    border-radius: 0% !important;
    background-color: white;
}


.login-form-label {
    margin-left: 10%;
    font-size: 1.8rem !important;
    margin-top: 1rem !important;
    font-family: monserratBlack;
}

  .login-btn-entrar{
    background-image:  url('../../../public/assets/img/StartPage/StartPage_Btn_Entrar.png');
    background-size: contain;
    background-repeat: no-repeat;
  
    max-width: 14.711rem; 
    width: 100%; 
    max-height: 6.059rem; 
    height: 100%;
    
    border-color: transparent;
    background-color: transparent;
}


@media only screen and (max-width: 800px) {

    .login-form-label {
        font-size: 1.7rem !important;
    }

    .containerAllLogin {
        flex-direction: column;
    }

    .containerLogin {
        justify-content: center;
    
        width: 100%;
        height: 70%;
    }
    
    .login-btns-container {
        width: 100%;
        height: 30%;
    }

    .login-btns-subcontainer {
        width: 100%;
        max-width: 32.147rem;
        height: auto;
        aspect-ratio: 32.147 / 24.025;
    
        margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 600px) {
    
    .login-form-label {
        font-size: .7rem !important;
    }
    
    .login-btns-container {
        justify-content: flex-start;

        margin-top: 1rem;

        width: 100%;
        height: 40%;
    }

    .login-btns-subcontainer {
        
        width: 90%;
        max-width: 32.147rem;
        height: auto;
        aspect-ratio: 32.147 / 24.025;

        margin: 0;
        padding: 0;
    }

    .login-form-expand-errors .mb-0 .todoContainer{
        margin-top: 1rem !important;
    }
  }

  