.float-right{
    float: right;
}

.btn-menu-logout{
    /* margin-top: 8rem; */
    background-image: url("../../../public/assets/img/General/Btn_Salir.png") !important;
    width: 7.1rem;
    height: 3rem;
    background-size: 100% 100%;
}

.menuGeneralContainer {
    display: flex;
    justify-content: center;

    height: 85vh; 
    width: 100%; 
}

.menuElementsContainer {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #ccc; /*Esto se va a comentar*/

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.menu-img {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #ccc; /*Esto se va a comentar*/

    background-image:  url('../../../public/assets/img/UI/Menu_Presentacion_1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    width: 50%;
    height: 80%;

    border-color: transparent;
    background-color: transparent;
}

.menuButtonsContainer {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #ccc; /*Esto se va a comentar*/

    width: 50%;
    height: 80%;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: space-around;
    overflow-y: auto;
}

.menuBtn{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    display: inline-block;
    transition: all 0.3s ease;
    max-width: 325px; 
    width: 100%; 
    max-height: 80px; 
    height: 100%; 

    padding-top: 1.5%;
    padding-right: 3%;
    padding-left: 4%;
    padding-bottom: 4%;

    border-color: transparent;
    background-color: transparent;

    margin-bottom: 5px;
}

.menuBtnComidas{
    background-image:  url('/src/ui/img/Menu/Menu_Btn_Comidas.png');
}

.menuBtnMisMomentos {
    background-image:  url('/src/ui/img/Menu/Menu_Btn_MiMomentos.png');
}

.menuBtnSabores {
    background-image:  url('/src/ui/img/Menu/Menu_Btn_Sabores.png');
}

.menuBtnHidratacion {
    background-image:  url('/src/ui/img/Menu/Menu_Btn_Hidratacion.png');
   
}

.menuBtnDesayuno {
    background-image:  url('/src/ui/img/Menu/Menu_Btn_Desayuno.png');
   
}

.menuBtnSocializacion {
    background-image:  url('/src/ui/img/Menu/Menu_Btn_Socializacion.png');
    
}


.btn-audio-menu{
    /* margin-left: 3rem;
    margin-top: 8rem; */
    z-index: 1;
}


/* Media query para dispositivos con pantalla pequeña (por ejemplo, teléfonos móviles) */
@media (max-width: 800px) {
    .menuElementsContainer {
        /*border: 1px solid black; /*Esto se va a comentar*/
        /*background: #ccc; /*Esto se va a comentar*/
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }

    .menuGeneralContainer{
        height: 80vh;
    }
    
    .menu-img {
        /*border: 1px solid black; /*Esto se va a comentar*/
        /*background: #ccc; /*Esto se va a comentar*/
    
        background-image:  url('../../../public/assets/img/UI/Menu_Presentacion_1.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    
        width: 100%;
        height: 30%;
        padding: 20px;
    
    
        border-color: transparent;
        background-color: transparent;
    }
    
    .menuButtonsContainer {
        /*border: 1px solid black; /*Esto se va a comentar*/
        /*background: #ccc; /*Esto se va a comentar*/
    
        width: 100%;
        height: 70%;
        padding: 20px;
    
        display: flex;
        flex-wrap: nowrap;

        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: space-between;
    }
    .menuBtn {
        /* background-size: contain; */
        max-width: 230px !important;
    }

    
    /* .menuGeneralContainer {
        margin-top: -3rem;
    } */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
   .btn-menu-logout{
        background-size: contain;
        width: 6rem;
   }
}
