.sabores-cooler-image{
    height: 38em;
    width: 14rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/ImgSabores/Cooler/Sidral/Img_Sidral_Cooler_01.png');
    background-repeat: no-repeat;
    float: right;
    margin-right: 6rem;
}

.sabores-cooler-slider{
    background-color: transparent;
    height: 29rem;
    width: 19.1rem;
    background-size: cover;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.sabores-cooler-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/UI/Img_content_01_.png');
    background-repeat: no-repeat;
}

.sabores-cooler-button{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbiriParrillas.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.sabores-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .sabores-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .sabores-cooler-slider-container{
    margin-top: 4rem;
  }

  .sabores-cooler-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 3rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .sabores-cooler-slider-image img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  .sabores-cooler-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    top:3.5rem;
    left: .21rem;
  }

  .sabores-background-transparent{
    background: transparent;
  }

  .sabores-cooler-btn{
    height: 4.8rem;
    width: 11.5rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 1.3rem;
    overflow: hidden;
  }

  .sabores-cooler-btn.oneBtn{
    margin-top: 5.5rem;
  }

  .sabores-cooler-btn.twoBtn{
    margin-top: 1rem;
  }
  .sabores-cooler-btn.threeBtn{
    margin-top: .5rem;
  }

  .sabores-cooler-btn.fourBtn{
    margin-top: .5rem;
  }

  .sabores-cooler-button p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .sabores-cooler-btn-selected{
    border-color: #40ca6e !important;
  }

  .sabores-cooler-btn img{
    max-height: 100%;
    max-width: 100%;
  }

  .sabores-cooler-flex-direction{
    flex-direction: column;
  }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sabores-cooler-image{
    height: 30em;
    width: 11.3rem;
    float: none;
    margin-right: 0;
    margin: 0 auto;
    margin-top: 2.5rem;
  }

  .sabores-cooler-slider{
  display: none;
  height: 26.5rem;
  width: 17.4rem;
  }

  .sabores-cooler-slider-mobile{
    display: block !important;
    margin-top: 3.5rem !important;
    margin-bottom: 2.6rem !important;
    margin: 0 auto;
  }

  .sabores-cooler-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    margin: 0 auto;
    margin-top: 1rem;
  }

  .sabores-cooler-image-mobile{
    display: none;
  }

  .sabores-cooler-slider-list{
    width: 16rem;
    height: 6rem;
    top:2rem;
    left: .34rem;
  }

  .sabores-cooler-slider-image{
    width: 14rem;
    margin-top: 3rem !important;
  }

  .sabores-cooler-slider-container{
    margin-top: 1.5rem;
  }

  .sabores-cooler-btn{
    height: 3.5rem;
    width: 9.3rem;
    margin-left: 1.1rem;
  }

  .sabores-cooler-btn.oneBtn{
    margin-top: 4.5rem;
  }

  .sabores-cooler-btn.twoBtn{
    margin-top: .8rem;
    height: 3.1rem;
  }
  .sabores-cooler-btn.threeBtn{
    margin-top: .8rem;
  }

  .sabores-cooler-btn.fourBtn{
    margin-top: .5rem;
    height: 4.4rem;
  }
}