.desayuno-image{
    height: 38em;
    width: 11rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/ImgDesayuno/Rack/Img_Desayuno_Rack.png');
    background-repeat: no-repeat;
    float: right;
    margin-right: 6rem;
}

.desayuno-slider{
    background-color: transparent;
    height: 29rem;
    width: 19.1rem;
    background-size: cover;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.desayuno-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/UI/Img_content_01_.png');
    background-repeat: no-repeat;
}

.desayuno-button{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbiriParrillas.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.desayuno-rack-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .desayuno-rack-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .desayuno-slider-container{
    margin-top: 4rem;
  }

  .desayuno-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 3rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .desayuno-slider-image img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto; 
  }

  .desayuno-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    top:3.5rem;
    left: .21rem;
    
  }

  .desayuno-rack-background-transparent{
    background: transparent;
  }

  .desayuno-btn{
    height: 4.9rem;
    width: 6.2rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 2.9rem;
    overflow: hidden;
  }

  .desayuno-btn.oneBtn{
    margin-top: 6.1rem;
  }

  .desayuno-btn.twoBtn{
    height: 5.4rem;
    margin-top: 2.1rem;
  }

  .desayuno-btn.threeBtn,
  .desayuno-btn.fourBtn{
    margin-top: 1.7rem;
    height: 5.8rem;
  }

  .desayuno-button p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .desayuno-btn-selected{
    border-color: #40ca6e !important;
  }

  .desayuno-btn img{
    max-height: 100%;
    max-width: 100%;
  }

  .desayuno-flex-direction{
    flex-direction: column;
  }

  .desayuno-rack-margin-top{
    margin-top: -1.5rem !important;
  }


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .desayuno-image{
    height: 30em !important;
    width: 8rem !important;
    float: none !important;
    margin-right: 0 !important;
    margin: 0 auto !important;
    margin-top: 2.5rem !important;
  }

  .desayuno-slider{
    display: none !important;
    height: 26.5em !important;
    width: 17.4rem !important;
  }

  .desayuno-slider-mobile{
    display: block !important;
    margin-top: 3.5rem !important;
    margin-bottom: 2.6rem !important;
    margin: 0 auto ;
  }

  .desayuno-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    margin: 0 auto;
    margin-top: 1rem;
  }

  .desayuno-image-mobile{
    display: none !important;
  }

  .desayuno-slider-list{
    width: 16rem !important;
    height: 6rem !important;
    top:2rem !important;
    left: .34rem !important;
  }

  .desayuno-slider-container{
    margin-top: 1.5rem !important;
  }

  .desayuno-slider-image{
    width: 14rem !important;
    margin-top: 3rem !important;
  }

  .desayuno-btn{
    height: 4rem;
    width: 4.7rem;
    margin-left: 2.4rem;
  }

  .desayuno-btn.oneBtn{
    margin-top: 4rem;
  }

  .desayuno-btn.twoBtn{
    height: 4.2rem;
    margin-top: 1.9rem;
  }

  .desayuno-btn.threeBtn,
  .desayuno-btn.fourBtn{
    margin-top: 1.4rem;
    height: 4rem;
  }
}

@media only screen and (min-width: 600px) {
  .desayuno-image, .desayuno-slider{
    display: block !important;
    margin-top: 2.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}