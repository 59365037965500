.desayuno-image{
    height: 38em;
    width: 11rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/ImgDesayuno/Rack/Img_Desayuno_Rack.png');
    background-repeat: no-repeat;
    float: right;
    margin-right: 6rem;
}

.desayuno-slider{
    background-color: transparent;
    height: 29rem;
    width: 19.1rem;
    background-size: cover;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.desayuno-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/UI/Img_content_01_.png');
    background-repeat: no-repeat;
}

.desayuno-button-ad{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbirComunicacion.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.desayuno-rack-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .desayuno-rack-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .desayuno-slider-container{
    margin-top: 4rem;
  }

  .desayuno-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 3rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .desayuno-slider-image img{
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto !important; 
  }

  .desayuno-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    top:3.5rem;
    left: .21rem;
    
  }

  .desayuno-rack-background-transparent{
    background: transparent;
  }


  .desayuno-btn-ad-comunicacion{
    height: 3.5rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 0.4rem;
    overflow: hidden;
  }

  .desayuno-btn-ad-comunicacion img{
    max-height: 100%;
    max-width: 100%;
  }

  .desayuno-btn-ad-comunicacion.threeAdd{
    margin-top: 10.5rem !important;
    position: absolute;
    margin-left: -6.4rem;
    height: 3rem;
    width: 3.1rem;
  }

  .desayuno-btn-ad-comunicacion.fourAdd{
    margin-top: 22rem !important;
    position: absolute;
    margin-left: -6.8rem;
    height: 3rem;
    width: 3.1rem;
  }

  .desayuno-btn-ad-comunicacion.fiveAdd{
    margin-top: 14.5rem !important;
    position: absolute;
    margin-left: -2.4rem;
    height: 3rem;
    width: 3.1rem;
  }


  .desayuno-btn-ad{
    height: 4.9rem;
    width: 6.2rem;
    background: transparent;
    border: transparent;
    margin-left: 2.9rem;
    overflow: hidden;
  }

  .desayuno-btn-ad.oneBtn{
    margin-top: 6rem;
  }

  .desayuno-btn-ad.twoBtn{
    height: 5.4rem;
    margin-top: 2.1rem;
  }

  .desayuno-btn-ad.threeBtn,
  .desayuno-btn-ad.fourBtn{
    margin-top: 1.7rem;
    height: 5.8rem;
  }

  .desayuno-button p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .desayuno-btn-ad-selected{
    border-color: #40ca6e !important;
  }

  .desayuno-btn-ad img{
    max-height: 100%;
    width: 100%;
  }

  .desayuno-flex-direction{
    flex-direction: column;
  }

  .desayuno-rack-margin-top{
    margin-top: -1.5rem !important;
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .desayuno-btn-ad{
    height: 4rem;
    width: 4.7rem;
    margin-left: 2.4em;
  }

  .desayuno-btn-ad.oneBtn{
    margin-top: 4.5rem;
  }

  .desayuno-btn-ad.twoBtn{
    height: 4.2rem;
    margin-top: 1.8rem;
  }

  .desayuno-btn-ad.threeBtn,
  .desayuno-btn-ad.fourBtn{
    margin-top: 1.5rem;
    height: 4rem;
  }

  .desayuno-btn-ad-comunicacion{
    height: 3rem;
    margin-left: 0.4rem;
  }

  .desayuno-btn-ad-comunicacion.threeAdd{
    margin-top: 7rem !important;
    margin-left: -5rem;
    height: 3rem;
    width: 3rem;
  }

  .desayuno-btn-ad-comunicacion.fourAdd{
    margin-top: 16.5rem !important;
    margin-left: -6rem;
    height: 3rem;
    width: 3rem;
  }

  .desayuno-btn-ad-comunicacion.fiveAdd{
    margin-top: 10.5rem !important;
  }
}


