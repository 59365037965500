.comidas-image{
    height: 38em;
    width: 12.5rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/ImgComidas/Rack/Img_Comidas_Rack_01.png');
    background-repeat: no-repeat;
    float: right;
    margin-right: 6rem;
}

.comidas-slider{
    background-color: transparent;
    height: 29rem;
    width: 19.1rem;
    background-size: cover;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.comidas-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/UI/Img_content_01_.png');
    background-repeat: no-repeat;
}

.comidas-button-add{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbirComunicacion.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.comidas-rack-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .comidas-rack-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .comidas-slider-container{
    margin-top: 4rem;
  }

  .comidas-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 3rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .comidas-slider-image img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    margin: 0 auto !important; 
  }

  .comidas-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    top:3.5rem;
    left: .21rem;
    
  }

  .comidas-rack-background-transparent{
    background: transparent;
  }


  .comidas-btn-ad-comunicacion{
    height: 4rem;
    width: 4rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 0.4rem;
    overflow: hidden;
  }

  .comidas-btn-ad-comunicacion img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-btn-ad-comunicacion.oneAdd{
    position: absolute;
    margin-top: 3rem;
    margin-left: 8.1rem;
  }

  .comidas-btn-ad-comunicacion.twoAdd{
    margin-top: 4rem !important;
    position: absolute;
    margin-left: -11.5rem;
  }

  .comidas-btn-ad-comunicacion.threeAdd{
    margin-top: 11.6rem !important;
    position: absolute;
    margin-left: -3.9rem;
  }

  .comidas-btn-ad-comunicacion.fourAdd{
    margin-top: 19rem !important;
    position: absolute;
    margin-left: -11.5rem;
  }

  .comidas-btn-ad{
    width: 11.5rem;
    background: transparent;
    border: transparent;
    margin-left: 0.5rem;
    overflow: hidden;
  }

  .comidas-btn-ad.oneBtn{
    height: 2.8rem;
    margin-top: .9rem;
  }

  .comidas-btn-ad.twoBtn{
    height: 6.5rem;
    margin-top: 3.5rem;
  }

  .comidas-btn-ad.threeBtn{
    height: 7.5rem;
    margin-top: 2.8rem;
  }

  .comidas-btn-ad.fourBtn{
    margin-top: 1.9rem;
    height: 7.5rem;
  }

  .comidas-button-add p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .comidas-btn-abrir{
    margin-top: -.9rem !important;
  }

  .comidas-btn-parrillas{
    margin-left: -1rem !important;
  }

  .comidas-btn-ad-selected{
    border-color: #40ca6e !important;
  }

  .comidas-btn-ad img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-flex-direction{
    flex-direction: column;
  }

  .comidas-rack-margin-top{
    margin-top: -1.5rem !important;
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .comidas-btn-ad{
    width: 9rem;
    margin-left: 0.5rem;
  }

  .comidas-btn-ad.oneBtn{
    height: 2.5rem;
    margin-top: .6rem;
  }

  .comidas-btn-ad.twoBtn{
    height: 5.2rem;
    margin-top: 2.9rem;
  }

  .comidas-btn-ad.threeBtn{
    height: 6rem;
    margin-top: 2rem;
  }

  .comidas-btn-ad.fourBtn{
    margin-top: 1.9rem;
    height: 6rem;
  }

  .comidas-btn-ad-comunicacion{
    height: 3.5rem;
    width: 3.5rem;
    margin-left: 0.4rem;
  }

  .comidas-btn-ad-comunicacion.oneAdd{
    margin-top: 2rem;
    margin-left: 6.1rem;
  }

  .comidas-btn-ad-comunicacion.twoAdd{
    margin-top: 3.4rem !important;
    margin-left: -9.2rem;
  }

  .comidas-btn-ad-comunicacion.threeAdd{
    margin-top: 9rem !important;
    margin-left: -3.4rem;
  }

  .comidas-btn-ad-comunicacion.fourAdd{
    margin-top: 8rem !important;
    margin-left: -9.2rem;
  }

}


