.comidas-anaqueles-comunicacion-image{
    height: 30rem;
    width: 100%;
    max-width: 34rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/ImgComidas/Anaqueles/AnaquelesSinFondo.png');
    background-repeat: no-repeat;
    float: right;
}

.comidas-anaqueles-comunicacion-slider{
    background-color: transparent;
    height: 23rem;
    width: 19.1rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.comidas-anaqueles-comunicacion-button{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/General/Btn_AbirComunicacion.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.comidas-anaqueles-comunicacion-rack-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    margin-top: -1rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .comidas-anaqueles-comunicacion-rack-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    margin-top: -1rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }


  .comidas-anaqueles-comunicacion-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 2rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .comidas-anaqueles-comunicacion-slider-image img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  .comidas-anaqueles-comunicacion-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    margin-top: 1rem;
    left: .21rem;
    
  }

  .comidas-anaqueles-comunicacion-rack-background-transparent{
    background: transparent;
  }


  .comidas-anaqueles-comunicacion-btn-ad-comunicacion{
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 3rem;
    overflow: hidden;
  }

  .comidas-anaqueles-comunicacion-btn-ad-comunicacion img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-anaqueles-comunicacion-btn-ad-comunicacion.oneAdd{
    position: absolute;
    margin-top: 11.6rem;
    width: 16.5rem;
    height: 3rem;
    margin-left: 0rem;
  }

  .comidas-anaqueles-comunicacion-btn-ad{
    height: 4rem;
    width: 13.7rem;
    background: transparent;
    border: transparent;
    margin-left: 3rem;
    overflow: hidden;
  }

  .comidas-anaqueles-comunicacion-btn-ad.oneBtn{
    margin-top: 10.8rem;
  }
  .comidas-anaqueles-comunicacion-button p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .comidas-anaqueles-comunicacion-btn-ad-selected{
    border-color: #40ca6e !important;
  }

  .comidas-anaqueles-comunicacion-btn-ad img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-anaqueles-comunicacion-flex-direction{
    flex-direction: column;
    margin-left: 3rem;
  }

  .comidas-anaqueles-comunicacion-rack-margin-top{
    margin-top: -1.5rem !important;
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .comidas-anaqueles-comunicacion-image{
    height: 22rem;
    width: 100%;
    float: none !important;
    margin-right: 0 !important;
    margin: 0 auto !important;
    margin-top: 1rem !important;
    max-width: 16.8rem !important;
  }

  .comidas-anaqueles-comunicacion-btn-ad-comunicacion{
    height: 3.5rem;
    width: 3.5rem;
  }

  .comidas-anaqueles-comunicacion-btn-ad{
    height: 5.9rem;
    width: 10.1rem;
    margin-left: .6rem;
  }

  .comidas-anaqueles-comunicacion-slider-container{
    margin-top: 0rem !important;
  }

  .comidas-anaqueles-comunicacion-flex-direction{
    margin-left: 0;
  }

  .glider, .glider-contain{
    margin-top: 0rem !important;
  }

  .comidas-anaqueles-comunicacion-button{
    height: 3.5rem;
    width: 5rem;
  }

  .comidas-anaqueles-comunicacion-rack-score-btn-return, .comidas-anaqueles-comunicacion-rack-score-btn-check{
    height: 2.5rem;
    width: 2.5rem;
  }

  .comidas-anaqueles-comunicacion-btn-ad.oneBtn{
    margin-top: 8rem;
    height: 3rem;
    margin-left: 0.5rem;
    width: 15.5rem;
  }

  

}

@media only screen and (min-width: 600px) {
  .comidas-anaqueles-comunicacion-button.start-txt-registrar{
    height: 4rem;
    margin-top: .6rem;
  }

  .comidas-anaqueles-comunicacion-btn-ad.oneBtn{
    margin-left: .8rem;
    width: 18.5rem;
  }

  .comidas-anaqueles-comunicacion-btn-ad-comunicacion.oneAdd{
    position: absolute;
    margin-top: 15.8rem;
    width: 20rem;
    height: 4rem;
    margin-left: 0rem;
  }

  .comidas-anaqueles-comunicacion-flex-direction{
    margin-left: 1.8rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .comidas-anaqueles-comunicacion-btn-ad.oneBtn{
    width: 25rem !important;
    margin-left: 1rem !important;
  }

  .comidas-anaqueles-comunicacion-btn-ad-comunicacion.oneAdd{
    position: absolute;
    margin-top: 15.3rem;
    width: 26.5rem;
    height: 4rem;
    margin-left: 0.2rem;
  }

  .comidas-anaqueles-comunicacion-image{
    max-width: 27rem !important;
  }

}

