.alert-container {
    position: fixed;
    bottom: 0;
    /*position: relative;*/
    width: 100%; /* o el ancho que desees */
    height: 100px; /* o el alto que desees */
}

.aler-card{
    position:absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -200px;
      width: 400px;
      height: 100px;
      background-color: transparent;
  }

.alert-img {
    width: 100%;
    height: 100%;
}

.alert-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(73, 73, 73); /* o el color que desees */
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
    font-family: monserratBold;
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

.alert-container.show {
    animation: slideIn 0.5s forwards;
}

.alert-container.hide {
    animation: slideOut 0.5s forwards;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .aler-card{
          margin-left: -170px;
          width: 340px;
          height: 100px;
          background-color: transparent;
    }
}