.comidas-tortillero-comunicacion-image{
    height: 38em;
    width: 14rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/ImgComidas/Tortillero/Img_Comidas_Tortillero_01.png');
    background-repeat: no-repeat;
    float: right;
    margin-right: 6rem;
}

.comidas-tortillero-comunicacion-slider{
    background-color: transparent;
    height: 29rem;
    width: 19.1rem;
    background-size: cover;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.comidas-tortillero-comunicacion-button{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbirComunicacion.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.comidas-tortillero-comunicacion-rack-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .comidas-tortillero-comunicacion-rack-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .comidas-tortillero-comunicacion-slider-container{
    margin-top: 4rem;
  }

  .comidas-tortillero-comunicacion-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 3rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .comidas-tortillero-comunicacion-slider-image img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    margin: 0 auto !important; 
  }

  .comidas-tortillero-comunicacion-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    top:3.5rem;
    left: .21rem;
    
  }

  .comidas-tortillero-comunicacion-rack-background-transparent{
    background: transparent;
  }


  .comidas-tortillero-comunicacion-btn-ad-comunicacion{
    height: 3.5rem;
    width: 3.5rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 0.4rem;
    overflow: hidden;
  }

  .comidas-tortillero-comunicacion-btn-ad-comunicacion img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-tortillero-comunicacion-btn-ad-comunicacion.oneAdd{
    position: absolute;
    margin-top: 13.5rem;
    margin-left: .6rem;
  }

  .comidas-tortillero-comunicacion-btn-ad-comunicacion.twoAdd{
    margin-top: 22.5rem !important;
    position: absolute;
    margin-left: -3.3rem;
  }

  .comidas-tortillero-comunicacion-btn-ad{
    height: 7.2rem;
    width: 12.4rem;
    background: transparent;
    border: transparent;
    margin-left: .8rem;
    overflow: hidden;
  }

  .comidas-tortillero-comunicacion-btn-ad.oneBtn{
    margin-top: 15.3rem;
  }

  .comidas-tortillero-comunicacion-btn-ad.twoBtn{
    margin-top: 1.8rem;
  }

  .comidas-tortillero-comunicacion-button p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .comidas-tortillero-comunicacion-btn-ad-selected{
    border-color: #40ca6e !important;
  }

  .comidas-tortillero-comunicacion-btn-ad img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-tortillero-comunicacion-flex-direction{
    flex-direction: column;
  }

  .comidas-tortillero-comunicacion-rack-margin-top{
    margin-top: -1.5rem !important;
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .comidas-tortillero-comunicacion-btn-ad{
    height: 5.9rem;
    width: 10.1rem;
    margin-left: .6rem;
  }

  .comidas-tortillero-comunicacion-btn-ad.oneBtn{
    margin-top: 12.3rem;
  }

  .comidas-tortillero-comunicacion-btn-ad.twoBtn{
    margin-top: 1.5rem;
  }

  .comidas-tortillero-comunicacion-btn-ad-comunicacion{
    height: 3.5rem;
    width: 3.5rem;
  }

  .comidas-tortillero-comunicacion-btn-ad-comunicacion.oneAdd{
    margin-top: 10.9rem;
    margin-left: .4rem;
  }

  .comidas-tortillero-comunicacion-btn-ad-comunicacion.twoAdd{
    margin-top: 18rem !important;
    margin-left: -3.3rem;
  }

  .comidas-tortillero-comunicacion-slider-container{
    margin-top: 1.5rem;
  }

}

