.comidas-cooler-btn-ad{
    border-color: transparent !important;
}

.comidas-cooler-button-ad{
    background-color: transparent;
    height: 5rem;
    width: 10rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbirComunicacion.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .6rem;
    border: transparent;
}

.comidas-cooler-button-ad p{
    margin: 0;
    margin-left: -5.5rem;
  }

  .comidas-cooler-btn-add{
    height: 4.8rem;
    width: 11rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 1.3rem;
    overflow: hidden;
    border-color: transparent !important;
  }

  .comidas-cooler-btn-add img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-cooler-btn-add.oneBtn{
    margin-top: 5.6em;
  }

  .comidas-cooler-btn-add.twoBtn,
  .comidas-cooler-btn-add.threeBtn,
  .comidas-cooler-btn-add.fourBtn{
    margin-top: .5rem;
  }

  .comidas-cooler-add{
    height: 2rem;
    width: 11rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 1.5rem;
    overflow: hidden;
  }

  .comidas-cooler-add.oneAdd{
    display: block;
    margin-top: -1.9rem;
    position: absolute;
  }

  .comidas-cooler-add.twoAdd{
    display: block;
    margin-top: -1.9rem;
    position: absolute;
  }

  .comidas-cooler-add.threeAdd{
    display: block;
    margin-top: -1.6rem;
    position: absolute;
  }

  .comidas-cooler-add.fourAdd{
    display: block;
    margin-top: -1.5rem;
    position: absolute;
  }

  .comidas-cooler-add img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-cooler-btn-selected-add{
    border-color: #40ca6e;
  }

  .comidas-comunication-flex-direction{
    flex-direction: column;
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .comidas-cooler-btn-add{
    height: 3.5rem;
    width: 9.3rem;
    margin-left: 1.1rem;
  }

  .comidas-cooler-btn-add.oneBtn{
    margin-top: 4.5rem;
  }

  .comidas-cooler-btn-add.twoBtn{
    margin-top: .8rem;
    height: 3.1rem;
  }
  .comidas-cooler-btn-add.threeBtn{
    margin-top: .8rem;
  }

  .comidas-cooler-btn-add.fourBtn{
    margin-top: .5rem;
    height: 4.4rem;
  }

  .comidas-cooler-add{
    height: 2rem;
    width: 9rem;
    margin-left: 1.2rem;
  }
}