.comidas-anaqueles-cooler-image{
    height: 30rem;
    width: 100%;
    max-width: 34rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/ImgComidas/Anaqueles/AnaquelesSinFondo.png');
    background-repeat: no-repeat;
    float: right;
}

.comidas-anaqueles-cooler-slider{
    background-color: transparent;
    height: 23rem;
    width: 19.1rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.comidas-anaqueles-cooler-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/UI/Img_content_01_.png');
    background-repeat: no-repeat;
}

.comidas-anaqueles-cooler-button{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/General/Btn_AbiriParrillas.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.comidas-anaqueles-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    margin-top: -1rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .comidas-anaqueles-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    margin-top: -1rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .comidas-anaqueles-cooler-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 2rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .comidas-anaqueles-cooler-slider-image img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  .comidas-anaqueles-cooler-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    margin-top: 1rem;
    left: .21rem;
  }

  .comidas-anaqueles-background-transparent{
    background: transparent;
  }

  .comidas-anaqueles-cooler-btn{
    height: 4rem;
    width: 13.7rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 3rem;
    overflow: hidden;
  }

  
  .comidas-anaqueles-cooler-btn.oneBtn{
    margin-top: 10.8rem;
  }

  .comidas-anaqueles-cooler-button p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .comidas-anaqueles-cooler-btn-selected{
    border-color: #40ca6e !important;
  }

  .comidas-anaqueles-cooler-btn img{
    max-height: 100%;
    max-width: 100%;
  }

  .comidas-anaqueles-cooler-flex-direction{
    flex-direction: column;
    margin-left: 3rem;
  }

  .comidas-anaqueles-margin-top{
    margin-top: -1.5rem !important;
  }


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .comidas-anaqueles-cooler-image{
    height: 22rem;
    width: 100%;
    float: none !important;
    margin-right: 0 !important;
    margin: 0 auto !important;
    margin-top: 1rem !important;
    max-width: 16.5rem !important;
  }

  .comidas-anaqueles-cooler-slider{
    display: none ;
    height: 20rem !important;
    width: 17.4rem !important;
  }

  .comidas-anaqueles-cooler-slider-mobile{
    display: block !important;
    margin-top: 3.5rem !important;
    margin-bottom: 2.6rem !important;
    margin: 0 auto ;
  }
  .comidas-anaqueles-cooler-open-button{
    background-color: transparent;
    height: 5rem;
    width: 19.1rem;
    margin: 0 auto;
    margin-top: -1rem;
  }

  .comidas-anaqueles-cooler-button{
    height: 3.5rem;
    width: 5rem;
  }

  .comidas-anaqueles-cooler-image-mobile{
    display: none !important;
  }

  .comidas-anaqueles-cooler-slider-list{
    width: 16rem !important;
    height: 6rem !important;
    left: .34rem;
    margin-top: 0rem;
  }

  .comidas-anaqueles-cooler-slider-image{
    width: 14rem !important;
    margin-top: 0rem !important;
  }

  .comidas-anaqueles-cooler-slider-container{
    margin-top: 0 !important;
  }

  .comidas-anaqueles-score-btn-return, .comidas-anaqueles-score-btn-check{
    height: 2.5rem;
    width: 2.5rem;
  }

  
  .comidas-anaqueles-cooler-btn.oneBtn{
    margin-top: 8rem;
    height: 3rem;
    margin-left: 0.5rem;
    width: 15.5rem;
  }

  .comidas-anaqueles-cooler-flex-direction{
    margin-left: 0;
  }

  .glider, .glider-contain{
    margin-top: 0rem !important;
  }
}

@media only screen and (min-width: 600px) {
  .comidas-anaqueles-cooler-image, .comidas-anaqueles-cooler-slider{
    display: block !important;
    margin-top: 2.5rem !important;
  }

  .comidas-anaqueles-cooler-btn.oneBtn{
    margin-left: .8rem;
    width: 18.5rem;
  }

  .comidas-anaqueles-cooler-open-button{
    height: 6rem;
  }

  .comidas-anaqueles-cooler-button.start-txt-registrar{
    height: 4rem;
    margin-top: .6rem;
  }

  .glider, .glider-contain{
    margin-left: .1rem ;
    margin-top: 0;
  }

  .comidas-anaqueles-cooler-flex-direction{
    margin-left: 1.8rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .comidas-anaqueles-cooler-btn.oneBtn{
    width: 25rem !important;
    margin-left: 1rem !important;
  }

  .comidas-anaqueles-cooler-image{
    max-width: 27rem !important;
  }

}