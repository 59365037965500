.desayuno-cooler-btn-ad{
    border-color: transparent !important;
}

.desayuno-cooler-button-ad{
    background-color: transparent;
    height: 5rem;
    width: 10rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbirComunicacion.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .6rem;
    border: transparent;
}

.desayuno-cooler-button-ad p{
    margin: 0;
    margin-left: -5.5rem;
  }

  .desayuno-cooler-btn-add{
    height: 3.9rem;
    width: 8rem;
    background: transparent;
    border-style: dashed;
    border-color: #1d1f20;
    margin-left: 1rem;
    overflow: hidden;
    border-color: transparent !important;
  }

  .desayuno-cooler-btn-add img{
    max-height: 100%;
    max-width: 100%;
  }

  .desayuno-cooler-btn-add.oneBtn{
    margin-top: .5rem;
  }

  .desayuno-cooler-btn-add.twoBtn,
  .desayuno-cooler-btn-add.threeBtn,
  .desayuno-cooler-btn-add.fourBtn,
  .desayuno-cooler-btn-add.fiveBtn{
    margin-top: .6rem;
  }

  .desayuno-cooler-add{
    height: 2rem;
    width: 8rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 1rem;
    overflow: hidden;
  }

  .desayuno-cooler-add.oneAdd{
    height: 3rem;
    margin-top: 1.7rem;
  }

  .desayuno-cooler-add.oneAdd img{
    max-height: 100%;
    max-width: 100%;
  }

  .desayuno-cooler-add.twoAdd{
    height: 2rem;
    margin-top: 3.2rem;
    position: absolute;
    margin-left: -8rem;
  }

  .desayuno-cooler-add.twoAdd img{
    max-height: 100%;
    max-width: 100%;
  }

  .desayuno-cooler-add img{
    max-height: 100%;
    max-width: 100%;
  }

  .desayuno-cooler-btn-selected-add{
    border-color: #40ca6e;
  }

  .desayuno-comunication-flex-direction{
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    .desayuno-cooler-btn-add{
      height: 3rem;
      width: 6rem;
      margin-left: 1rem;
    }

    .desayuno-cooler-add{
      height: 2rem !important;
      width: 6rem;
      margin-left: 1rem;
      margin-top: 1.3rem !important;
    }

    .desayuno-cooler-add.twoAdd{
      height: 1.5rem !important;
      margin-top: 2.5rem !important;
      
      margin-left: -6.1rem;
    }
 }