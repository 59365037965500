.float-right{
    float: right;
}

.Enfriador-image{
    background-color: red;
    height: 35.5em;
    width: 100%;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/ImgMisMomentos/Enfriador/Opcion 1/Enfriador_3Puertas.png');
    background-repeat: no-repeat;
}

.Enfriador-slider{
    background-color: transparent;
    height: 29rem;
    width: 19.1rem;
    background-size: cover;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.bottom-button {
    background-image:  url('../../../../public/assets/img/UI/Img_button_next_01_.png');
    background-size: contain;
    background-repeat: no-repeat;

    display: inline-block;
    transition: all 0.3s ease; 
    max-width: 200px; 
    width: 100%; 
    max-height: 100px; 
    height: 100%; 

    border-color: transparent;
    background-color: transparent;
}

.Enfriador-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/UI/Img_content_01_.png');
    background-repeat: no-repeat;
}

.Enfriador-button{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbiriParrillas.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .Enfriador-slider-container{
    margin-top: 4rem;
  }

  .Enfriador-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 3rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .Enfriador-slider-image img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  .Enfriador-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    top:3.5rem;
    left: .21rem;
    
  }

  .my-moments-enfriador-background-transparent{
    display: none !important;
  }

  .my-moments-Enfriador-btn{
    height: 4.9rem;
    width: 11rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 1.3rem;
    overflow: hidden;
  }

  .my-moments-Enfriador-btn.oneBtn{
    margin-top: 5.4rem;
    width: 4.5rem;
    margin-left: 1.3rem;
  
  }

  .my-moments-Enfriador-btn.twoBtn{
    width: 4.5rem;
  }

  .my-moments-Enfriador-btn.threeBtn{
    margin-left: 2.8rem;
    width: 9.7rem;
  }

  .my-moments-Enfriador-btn.fourBtn{
    width: 3rem;
    margin-left: 9rem;
  }

  .my-moments-Enfriador-btn.fiveBtn{
    float: right;
    width: 3rem;
    margin-right: 1.5rem;
  }
  
  .my-moments-Enfriador-btn.twoBtn,
  .my-moments-Enfriador-btn.threeBtn,
  .my-moments-Enfriador-btn.fourBtn,
  .my-moments-Enfriador-btn.fiveBtn{
    margin-top: 0rem;
  }

  .Enfriador-button p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .my-moments-Enfriador-btn-selected{
    border-color: #40ca6e !important;
  }

  .my-moments-Enfriador-btn img{
    height: 100%;
    width: 100%;
    vertical-align: initial;
    object-fit: cover;
  }

  .my-moments-Enfriador-flex-direction{
    flex-direction: column;
  }

  .my-moments-margin-top{
    margin-top: -1.5rem !important;
  }


 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .Enfriador-image{
    height: 35rem;
    width: 100%;
    margin: 0 auto;
    margin-top: 2.5rem;
    background-size: 100% 100%;
    background-position:  0 0;
    white-space: nowrap;
    background-attachment: local;
    overflow-x: scroll;
    max-width: 16.5rem;
}

.Enfriador-slider{
  display: none;
  height: 26.5em;
  width: 17.4rem;
}


.Enfriador-slider-mobile{
  display: block !important;
  margin-top: 3.5rem !important;
  margin-bottom: 2.6rem !important;
  margin: 0 auto;
}

.Enfriador-image-mobile{
  display: none;
}

.Enfriador-open-button{
  background-color: transparent;
  height: 8rem;
  width: 19.1rem;
  margin: 0 auto;
  margin-top: 1rem;
}

.Enfriador-slider-list{
  width: 16rem;
  height: 6rem;
  top: 2rem;
  left: .34rem;
}

.Enfriador-slider-image{
  width: 14rem;
  margin-top: 3rem !important;
}

.Enfriador-slider-container{
  margin-top: 1.5rem;
}

.my-moments-Enfriador-btn {
  height: 4rem;
  width: 9.3rem;
  margin-left: 1.1rem;
}

.my-moments-Enfriador-btn.oneBtn{
  margin-top: 5.5rem;
  margin-left: 1.1rem;
  width: 4.9rem;
}

.my-moments-Enfriador-btn.twoBtn{
  margin-left: .2rem;
  width: 4.9rem;
}

.my-moments-Enfriador-btn.threeBtn{
  margin-left: 2rem;
  width: 10rem;
}

.my-moments-Enfriador-btn.fourBtn{
  width: 2.5rem;
  margin-right: 1.3rem;
}

.my-moments-Enfriador-btn.fiveBtn{
  width: 2.5rem;
  margin-right: -18.2rem;
}

.my-moments-Enfriador-btn.twoBtn,
.my-moments-Enfriador-btn.threeBtn,
.my-moments-Enfriador-btn.fourBtn,
.my-moments-Enfriador-btn.fiveBtn{
  margin-top: .2rem;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Enfriador-slider, .Enfriador-image{
    display: block;
    margin-top: 2.5rem !important;
    margin-bottom: 1.5rem !important;
    margin: 0 auto;
  }
  

  .Enfriador-image{
    max-width: 50rem;
    margin: 0 auto;
  }

  .my-moments-Enfriador-btn {
    height: 4rem;
    width: 9.3rem;
  }
  
  .my-moments-Enfriador-btn.oneBtn{
    margin-top: 5.3rem;
    margin-left: 2rem;
    width: 5rem;
  }
  
  .my-moments-Enfriador-btn.twoBtn{
    margin-left: .5rem;
    width: 5rem;
  }
  
  .my-moments-Enfriador-btn.threeBtn{
    margin-left: 3rem;
    width: 10.8rem;
  }
  
  .my-moments-Enfriador-btn.fourBtn{
    width: 2.5rem;
    margin-left: 11.3rem;
  }
  
  .my-moments-Enfriador-btn.fiveBtn{
    width: 2.5rem;
    margin-right: 1.6rem;
    margin-top: .9rem;
  }

  .Enfriador-open-button {
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    margin: 0 auto;
    margin-top: 1rem;
}

.Enfriador-slider-mobile {
  display: block !important;
  margin: 0 auto;
}

  .Enfriador-image-mobile{
    display: none !important;
  }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .my-moments-enfriador-background-transparent{
    background: transparent !important;
    display: block !important;
  }

  .Enfriador-image{
    max-width: 36.5rem;
  }

  .Enfriador-image-mobile{
    display: block !important;
  }

  .my-moments-Enfriador-btn {
    height: 4rem;
    width: 9.3rem;
  }
  
  .my-moments-Enfriador-btn.oneBtn{
    margin-top: 5.3rem;
    margin-left: 1.2rem;
    width: 4.9rem;
  }
  
  .my-moments-Enfriador-btn.twoBtn{
    margin-left: .3rem;
    width: 4.9rem;
  }
  
  .my-moments-Enfriador-btn.threeBtn{
    margin-left: 2.2rem;
    width: 9.5rem;
  }
  
  .my-moments-Enfriador-btn.fourBtn{
    width: 2.5rem;
    margin-left: 9.8rem;
  }
  
  .my-moments-Enfriador-btn.fiveBtn{
    width: 2.5rem;
    margin-right: 1.2rem;
    margin-top: .9rem;
  }
}