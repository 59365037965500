.my-moments-cooler-btn-ad{
    border-color: transparent !important;
}

.cooler-button-ad{
    background-color: transparent;
    height: 5rem;
    width: 10rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbirComunicacion.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .6rem;
    border: transparent;
}

.cooler-button-ad p{
    margin: 0;
    margin-left: -5.5rem;
  }

  .my-moments-cooler-btn-add{
    height: 4rem;
    width: 11rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 1.3rem;
    overflow: hidden;
    border-color: transparent !important;
  }

  .my-moments-cooler-btn-add img{
    height: 100%;
    width: auto;
  }

  .my-moments-cooler-btn-add.oneBtn{
    margin-top: 2rem;
  }

  .my-moments-cooler-btn-add.twoBtn,
  .my-moments-cooler-btn-add.threeBtn,
  .my-moments-cooler-btn-add.fourBtn,
  .my-moments-cooler-btn-add.fiveBtn{
    margin-top: 1rem;
  }

  .my-moments-cooler-add{
    height: 2.3rem;
    width: 11rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 1.3rem;
    overflow: hidden;
  }

  .my-moments-cooler-add.oneAdd{
    margin-top: 2.2rem;
  }

  .my-moments-cooler-add.twoAdd{
    display: block;
    margin-top: -1.5rem;
    position: absolute;
  }

  .my-moments-cooler-add img{
    height: 100%;
    width: auto;
  }

  .my-moments-cooler-btn-selected-add{
    border-color: #40ca6e;
  }

  .my-moments-comunication-flex-direction{
    flex-direction: column;
  }

   /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
  .my-moments-cooler-btn-add{
    height: 3.5rem;
    width: 9.3rem;
    margin-left: 1.1rem;
  }

  .my-moments-cooler-btn-add.oneBtn{
    margin-top: 1rem;
  }

  .my-moments-cooler-btn-add.twoBtn,
  .my-moments-cooler-btn-add.threeBtn,
  .my-moments-cooler-btn-add.fourBtn,
  .my-moments-cooler-btn-add.fiveBtn{
    margin-top: .5rem;
  }

  .my-moments-cooler-add{
    height: 2rem;
    width: 9.3rem;
    margin-left: 1.1rem;
  }

  .my-moments-cooler-add.oneAdd{
    margin-top: 2rem;
  }

  .my-moments-cooler-add.twoAdd{
    margin-top: -1rem;
  }
}