.generalContainderComidas {
    align-items: center; 
    justify-content: center; 
    height: 100vh; 
    width: 100vw; 
    display: flex;
    margin-top: -5rem ;
}

.containerInicioComidas {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #ccc; /*Esto se va a comentar*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    height: 100%;
}

.image-containerComidas {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #585858; /*Esto se va a comentar*/
    width: 80%;
    height: 80%;
    padding: 20px;
    position: relative;
    background-image: url('../img/Comidas/Background_Inicio_Comidas_1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.buttons-containerComidas {
    /*border: 1px solid black; /*Esto se va a comentar*/
    /*background: #585858; /*Esto se va a comentar*/
    width: 90%;
    height: 90%;
    padding: 20px;
    display: flex;
    position: relative; 
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.top-buttonComidas {
    background-image:  url('/src/ui/img/Comidas/Comidas_Txt_Inicio.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    display: inline-block;
    transition: all 0.3s ease;
    max-width: 450px; 
    width: 100%; 
    max-height: 200px; 
    height: 100%; 

    border-color: transparent;
    background-color: transparent;
}

.bottom-buttonComidas {
    background-image:  url('/src/ui/img/Comidas/Comidas_Btn_Inicio.png');
    background-size: contain;
    background-repeat: no-repeat;

    display: inline-block;
    transition: all 0.3s ease; 
    max-width: 250px; 
    width: 100%; 
    max-height: 100px; 
    height: 100%; 

    border-color: transparent;
    background-color: transparent;
}

.btn-audio-comidas{
    z-index: 1;
}

/* Media query para dispositivos con pantalla pequeña (por ejemplo, teléfonos móviles) */
@media (max-width: 800px) {
    .containerInicioComidas {
        flex-direction: column;
    }
    
    .buttons-containerComidas {
        padding: 20px;
        position: relative; 
        
    }

    .top-buttonComidas {
        max-width: 350px; 
        max-height: 100px; 
    }

    .bottom-buttonComidas {
        max-width: 180px; 
        max-height: 80px; 
        margin-top: -10rem;
    }
    .generalContainderComidas{
        margin-top: -4rem;;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .buttons-containerComidas {
        /*border: 1px solid black; /*Esto se va a comentar*/
        /*background: #585858; /*Esto se va a comentar*/
        width: 100%;
        height: 100%;
        display: flex;
        position: relative; 
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        margin-top: -6rem;
    }

    .top-buttonComidas {
        margin-bottom: 3rem;
    }
}
