.sabores-btn-fresca{
    /* background-image:  url('../../../../public/assets/img/General/Btn_Ejecutar.png'); */
    background-color: green;
    
}

.sabores-btn-sidral{
    /* background-image:  url('../../../../public/assets/img/General/Btn_Ejecutar.png'); */
    background-color: brown;
    
}

.sabores-btn-eleccion{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  
    max-width: 14.711rem; 
    width: 14rem; 
    max-height: 6.059rem; 
    height: 5rem;
    
    border-color: transparent;
    /* background-color: transparent; */

    color: white;
    font-size: x-large;
  }
  .vertical-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .button-container {
    text-align: center;
  }

.heigh-100vh{
    height: 100vh;
}

  