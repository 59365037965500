.hidratacion-image{
    height: 38em;
    width: 11rem;
    background-size: 100% 100%;
    background-image: url('../../../../public/assets/img/ImgHidratacion/Rack/Img_Hidratacion_Rack.png');
    background-repeat: no-repeat;
    float: right;
    margin-right: 6rem;
}

.hidratacion-slider{
    background-color: transparent;
    height: 29rem;
    width: 19.1rem;
    background-size: cover;
    background-image: url('../../../../public/assets/img/UI/Img_content_parrilla_.png');
    background-repeat: no-repeat;
    margin-bottom: 1rem;
}

.hidratacion-open-button{
    background-color: transparent;
    height: 8rem;
    width: 19.1rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/UI/Img_content_01_.png');
    background-repeat: no-repeat;
}

.hidratacion-button-add{
    background-color: transparent;
    height: 4.5rem;
    width: 7rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/General/Btn_AbirComunicacion.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .8rem;
    border: transparent;
}

.hidratacion-rack-score-btn-return{
    background-image:  url('../../../../public/assets/img/UI/Img_button_return_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: left;
    left: .8rem;
  }

  .hidratacion-rack-score-btn-check{
    background-image:  url('../../../../public/assets/img/UI/Img_button_accept_.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    padding-top: 0.3rem;
    display:  inline-block;
    border-color: transparent;
    position: relative;
    float: right;
  }

  .hidratacion-slider-container{
    margin-top: 4rem;
  }

  .hidratacion-slider-image{
    width: 15.5rem;
    height: 11rem;
    margin-top: 3rem !important;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  .hidratacion-slider-image img{
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto !important; 
  }

  .hidratacion-slider-list{
    background-image:  url('../../../../public/assets/img/UI/Img_bg_reel_.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 17.9rem;
    height: 6rem;
    display:  flex;
    border-color: transparent;
    position: relative;
    top:3.5rem;
    left: .21rem;
    
  }

  .hidratacion-rack-background-transparent{
    background: transparent;
  }


  .hidratacion-btn-ad-comunicacion{
    height: 3.5rem;
    width: 3.5rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    overflow: hidden;
  }

  .hidratacion-btn-ad-comunicacion img{
    max-height: 100%;
    max-width: 100%;
  }

  .hidratacion-btn-ad-comunicacion.oneAdd{
    margin-top: 10rem;
    position: absolute;
    margin-left: 5.8rem;
  }

  .hidratacion-btn-ad-comunicacion.twoAdd{
    margin-top: 34rem !important;
    position: absolute;
    margin-left: -3.4rem;
    height: 3rem;
  }

  .hidratacion-btn-ad{
    height: 4.9rem;
    width: 6.2rem;
    background: transparent;
    border: transparent;
    margin-left: 2.9rem;
    overflow: hidden;
  }

  .hidratacion-btn-ad.oneBtn{
    margin-top: 6rem;
  }

  .hidratacion-btn-ad.twoBtn{
    height: 5.4rem;
    margin-top: 3rem;
  }

  .hidratacion-btn-ad.threeBtn,
  .hidratacion-btn-ad.fourBtn{
    margin-top: 1.5rem;
    height: 5.8rem;
  }

  .hidratacion-button-add p{
    margin: 0;
    margin-left: -2.5rem;
  }

  .hidratacion-btn-ad-selected{
    border-color: #40ca6e !important;
  }

  .hidratacion-btn-ad img{
    max-height: 100%;
    width: 100%;
  }

  .hidratacion-flex-direction{
    flex-direction: column;
  }

  .hidratacion-rack-margin-top{
    margin-top: -1.5rem !important;
  }

    /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .hidratacion-btn-ad{
    height: 4rem;
    width: 4.8rem;
    margin-left: 2.3rem;
  }

  .hidratacion-btn-ad.oneBtn{
    margin-top: 4.5rem;
  }

  .hidratacion-btn-ad.twoBtn{
    height: 4rem;
    margin-top: 2.5rem;
  }

  .hidratacion-btn-ad.threeBtn,
  .hidratacion-btn-ad.fourBtn{
    margin-top: 1.4rem;
    height: 4.3rem;
  }

  .hidratacion-btn-ad-comunicacion{
    height: 3.4rem;
    width: 3.4rem;
    margin-left: 0rem;
  }

  .hidratacion-btn-ad-comunicacion.oneAdd{
    margin-top: 7.5rem;
    margin-left: 4rem;
  }

  .hidratacion-btn-ad-comunicacion.twoAdd{
    margin-top: 26.5rem !important;
    margin-left: -2.9rem;
    height: 3rem;
    width: 3rem;
  }

  .hidratacion-btn-ad-comunicacion.threeAdd{
    margin-top: 5.7rem !important;
    margin-left: -4.95rem;
    height: 3rem;
    width: 3rem;
  }

  .hidratacion-btn-ad-comunicacion.fourAdd{
    margin-top: 5.5rem !important;
    position: absolute;
    margin-left: -2.35rem;
    height: 2.6rem;
    width: 2.5rem;
  }

  .hidratacion-btn-ad-comunicacion.fiveAdd{
    margin-top: 7rem !important;
    position: absolute;
    margin-left: -4.95rem;
    height: 2.6rem;
    width: 2.5rem;
  }

}


