body{
    height: 100vh;
}

.score-container{
    background-image: url('../../../public/assets/img/UI/Img_Cuestionario_Puntuacion.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    width: 27.2rem;
    height: 20rem;
}


.height-100vh{
    height: 100vh;
}

.center-div{
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-punctuation{
    font-size: xx-large;
    font-weight: bold;
    color: white;
    margin-left: 1.6rem;
    padding-top: .5rem;
}

.body-punctuation{
    font-size: 4.5rem;
    font-weight: bold;
    color: #eb262c;
    margin-left: 7.7rem;
    padding-top: 5.8rem;
    
    font-family: monserratBold;
}

.margin-auto{
    margin: 0 auto;
  }


  .score-top-button{
    background-image:  url('../../../public/assets/img/ImgCuestionario/Btn_Cuestionario_Listo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    transition: all 0.3s ease;
    width: 13.2rem;
    height: 4rem; 
    border-color: transparent;
    background-color: transparent;
  }

  .score-buttons-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    position: relative; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .score-container{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        width: 20rem;
        height: 16rem;
    }

    .body-punctuation{
        font-size: 4rem;
        font-weight: bold;
        color: #eb262c;
        margin-left: 4.7rem;
        padding-top: 4.2rem;
    }

    .score-top-button{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        transition: all 0.3s ease;
        width: 13.2rem;
        height: 4rem; 
        border-color: transparent;
        background-color: transparent;
      }
}