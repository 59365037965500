@font-face {
  font-family: monserratBlack;
  src: url("../public/assets/fonts/montserrat/Montserrat-Black.ttf");
}

@font-face {
  font-family: monserratBold;
  src: url("../public/assets/fonts/montserrat/Montserrat-Bold.ttf");
}

body{
  background-image: url('../public/assets/img/UI/TEXTURAS/BG.png');
  background-size: 25rem;
  background-repeat: repeat;
}

option:first-of-type {
  color: #9e9e9e;
}

.btn-hide{
  background: transparent;
}



.start-txt-registrar{
  margin: 0 auto;
  color: white;
  font-size: smaller;
  font-weight: 900;
}

.bg-transparent{
  background-color: transparent !important;
  border-color: transparent;
}

.no-diplay-none{
  display: block !important;
  margin-top: 0;
  white-space: initial;
}


.float-right{
  float: right;
}

.glider-slide img{
  margin-bottom: .6rem !important;
}

.btn-sliderlist-img{
  padding: .30rem !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .container{
      position: inherit;
      top: 0;
      left: 0;
      transform: none;
    }

    .float-right{
      float: right;
      margin-top: .5rem;
    }
  }