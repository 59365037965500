.audioButton {
    width: 65px;
    height: 65px;
    background-image: url('../../../public/assets/img/UI/Img_button_repeat_.png');
    background-size: contain; 
    background-repeat: no-repeat; 
    background-position: center; 
    background-color: transparent;
    border:transparent;
    z-index: 1000; 
  }

  .filter-brightness{
    filter: brightness(0.5);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .audioButton {
    width: 55px;
  }
}