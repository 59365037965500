.hidratacion-cooler-btn-ad{
    border-color: transparent !important;
}

.hidratacion-cooler-button-ad{
    background-color: transparent;
    height: 5rem;
    width: 10rem;
    background-size: contain;
    background-image: url('../../../../public/assets/img/UI/Img_button_menu_.png');
    background-repeat: no-repeat;
    margin-left: .5rem;
    margin-top: .6rem;
    border: transparent;
}

.hidratacion-cooler-button-ad p{
    margin: 0;
    margin-left: -5.5rem;
  }

  .hidratacion-cooler-btn-add{
    height: 4.5rem;
    width: 8.9rem;
    background: transparent;
    border-style: dashed;
    border-color: #1d1f20;
    margin-left: 1.1rem;
    overflow: hidden;
    border-color: transparent !important;
  }

  .hidratacion-cooler-btn-add img{
    max-height: 100%;
    width: 100%;
  }

  .hidratacion-cooler-btn-add.oneBtn{
    margin-top: 1rem;
  }

  .hidratacion-cooler-btn-add.twoBtn,
  .hidratacion-cooler-btn-add.threeBtn,
  .hidratacion-cooler-btn-add.fourBtn{
    margin-top: .9rem;
  }

  .hidratacion-cooler-add{
    height: 2rem;
    width: 8.9rem;
    background: transparent;
    border-style: dashed;
    border-color: #0a91e0;
    margin-left: 1.1rem;
    overflow: hidden;
  }

  .hidratacion-cooler-add.oneAdd{
    height: 3rem;
    margin-top: 1.7rem;
  }

  .hidratacion-cooler-add.oneAdd img{
    height: 100%;
    width: auto;
  }

  .hidratacion-cooler-add.twoAdd{
    display: block;
    margin-top: -1rem;
    position: absolute;
  }

  .hidratacion-cooler-add.threeAdd{
    display: block;
    margin-top: -7.6rem;
    position: absolute;
  }

  .hidratacion-cooler-add.fourAdd{
    display: block;
    margin-top: -2rem;
    position: absolute;
  }

  .hidratacion-cooler-add img{
    height: 100%;
    width: auto;
  }

  .hidratacion-cooler-btn-selected-add{
    border-color: #40ca6e;
  }

  .hidratacion-comunication-flex-direction{
    flex-direction: column;
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .hidratacion-cooler-btn-add{
    height: 3.5rem;
    width: 8.9rem;
    margin-left: 1.2rem;
  }

  .hidratacion-cooler-btn-add.oneBtn{
    margin-top: .9rem;
  }

  .hidratacion-cooler-btn-add.twoBtn,
  .hidratacion-cooler-btn-add.threeBtn,
  .hidratacion-cooler-btn-add.fourBtn{
    margin-top: .8rem;
  }

  .hidratacion-cooler-add{
    height: 1.8rem;
    width: 9.2rem;
  }

  .hidratacion-cooler-add.oneAdd{
    height: 2.5rem;
    margin-top: 1.5rem;
  }

  .hidratacion-cooler-add.oneAdd img{
    max-height: 100%;
    max-width: 100%;
  }

  .hidratacion-cooler-add.twoAdd{
    display: block;
    margin-top: -1.3rem;
    position: absolute;
  }

  .hidratacion-cooler-add.threeAdd{
    display: block;
    margin-top: -6.4rem;
    position: absolute;
  }

  .hidratacion-cooler-add.fourAdd{
    display: block;
    margin-top: -2rem;
    position: absolute;
  }
}